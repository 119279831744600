import { useRef, useState, useEffect, useCallback } from "react";
import HTMLFlipBook from "react-pageflip";
import Page from "./page/Page";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

import "./App.css";
import PageCover from "./PageCover/PageCover";
import { Button } from "@mui/material";
import { createTrue } from "typescript";

import logo from "./assets/VT_logo.svg";
import UWTSD from "./assets/UWTSD.png";

const book = [
  { image: "content_page.jpg", welshImage: "content_page_welsh.jpg" },
  {
    image: "VC_page.jpg",
    welshImage: "VC_page_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/Whw__9Z8KwE",
    welshVideoUrl: "https://www.youtube.com/embed/TkH6LuXtLEM",
  },
  {
    image: "Mary_AnnConstantine_page_1.jpg",
    welshImage: "Mary_AnnConstantine_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/tK7IrZszbTE ",
    welshVideoUrl: "https://www.youtube.com/embed/fL-ncyc8xqg",
    socialMediaLinks: [
      {
        type: "other",
        text: "portspastpresent",
        link: "https://portspastpresent.eu/",
        id: 0,
      },
      {
        type: "other",
        text: "curiostravellers",
        link: "https://curioustravellers.ac.uk/en/",
        id: 1,
      },
    ],
  },
  {
    image: "Mary_AnnConstantine_page_2.jpg",
    welshImage: "Mary_AnnConstantine_page_2_welsh.jpg",
    iframe: "https://portspastpresent.eu/",
  },
  {
    image: "Kyle_Erickson_page_1.jpg",
    welshImage: "Kyle_Erickson_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/X1IYjGqwuTg ",
    welshVideoUrl: "https://www.youtube.com/embed/X1IYjGqwuTg ",
    socialMediaLinks: [
      {
        type: "twitter",
        link: "https://twitter.com/epimanes?lang=en",
        id: 0,
      },
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/kyle-erickson-2434b43/?originalSubdomain=uk",
        id: 1,
      },
      {
        type: "other",
        text: "lamp.academia",
        link: "https://lamp.academia.edu/KyleErickson",
        id: 2,
      },
    ],
  },
  {
    image: "Kyle_Erickson_page_2.jpg",
    welshImage: "Kyle_Erickson_page_2_welsh.jpg",
    webLink: "https://lamp.academia.edu/KyleErickson",
  },
  {
    image: "Annette_FilleryTravis_page_1.jpg",
    welshImage: "Annette_FilleryTravis_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/VqyABrUp4oE",
    welshVideoUrl: "https://www.youtube.com/embed/VqyABrUp4oE",
    socialMediaLinks: [
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/annetteuwtsd/",
        id: 0,
      },
      {
        type: "other",
        text: "researchgate",
        link: "https://www.researchgate.net/profile/Annette-Fillery-Travis",
        id: 1,
      },
    ],
  },
  {
    image: "Annette_FilleryTravis_page_2.jpg",
    welshImage: "Annette_FilleryTravis_page_2_welsh.jpg",
  },
  {
    image: "Mirjam_Plantinga_page_1.jpg",
    welshImage: "Mirjam_Plantinga_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/OxeGuZNGRH4",
    welshVideoUrl: "https://www.youtube.com/embed/OxeGuZNGRH4",
  },
  {
    image: "Mirjam_Plantinga_page_2.jpg",
    welshImage: "Mirjam_Plantinga_page_2_welsh.jpg",
    iframe: "https://uwtsdc.digitise.tours",
  },
  {
    image: "Stephen_Sommerville_page_1.jpg",
    welshImage: "Stephen_Sommerville_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/vxxio1Ez1cU",
    welshVideoUrl: "https://www.youtube.com/embed/vxxio1Ez1cU",
  },
  {
    image: "Stephen_Sommerville_page_2.jpg",
    welshImage: "Stephen_Sommerville_page_2_welsh.jpg",
  },
  {
    image: "Louise_Steel_page_1.jpg",
    welshImage: "Louise_Steel_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/0d2wJ689PVc",
    welshVideoUrl: "https://www.youtube.com/embed/0d2wJ689PVc",
    socialMediaLinks: [
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/louise-steel-304b586b/?originalSubdomain=uk",
        id: 0,
      },
    ],
  },
  {
    image: "Louise_Steel_page_2.jpg",
    welshImage: "Louise_Steel_page_2_welsh.jpg",
  },
  {
    image: "Catrin_Williams_page_1.jpg",
    welshImage: "Catrin_Williams_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/m_5qDhH110A",
    welshVideoUrl: "https://www.youtube.com/embed/s_ZsjfA-pYk",
  },
  {
    image: "Catrin_Williams_page_2.jpg",
    welshImage: "Catrin_Williams_page_2_welsh.jpg",
    iframe: "https://uwtsdl.digitise.tours",
  },
  {
    image: "heather_williams_page_1.jpg",
    welshImage: "heather_williams_page_1_welsh.jpg",
    videoUrl: "https://www.youtube.com/embed/rfuTOd62lBY",
    welshVideoUrl: "https://www.youtube.com/embed/GCkmCzV4VT8",
    socialMediaLinks: [
      {
        type: "twitter",
        link: "https://twitter.com/HeatherHMW?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        id: 0,
      },
      {
        type: "other",
        text: "etw.bangor",
        link: "http://etw.bangor.ac.uk/welcome",
        id: 1,
      },
    ],
  },
  {
    image: "heather_williams_page_2.jpg",
    welshImage: "heather_williams_page_2_welsh.jpg",
    webLink: "https://etw.bangor.ac.uk/welcome",
  },
  {
    image: "Carlene_Campbell.jpg",
    welshImage: "Carlene_Campbell_welsh.jpg",
    socialMediaLinks: [
      {
        type: "facebook",
        link: "https://www.facebook.com/carbell00/about",
        id: 0,
      },
      {
        type: "twitter",
        link: "https://twitter.com/carlnjam?lang=en",
        id: 1,
      },
      {
        type: "other",
        text: "knowlegewithoutprofit",
        link: "https://www.knowledgewithprofit.com/",
        id: 2,
      },
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/dr-carlene-campbell-37a5a717/",
        id: 3,
      },
    ],
  },
  {
    image: "Katharina_Zinn.jpg",
    welshImage: "Katharina_Zinn_welsh.jpg",
    socialMediaLinks: [
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/katharina-zinn-5a78a217/ ",
        id: 0,
      },
      {
        type: "twitter",
        link: "https://twitter.com/katharinazinn?lang=en",
        id: 1,
      },
      {
        type: "other",
        text: "lamp.academia",
        link: "https://lamp.academia.edu/KatharinaZinn ",
        id: 2,
      },
      {
        type: "other",
        text: "researchgate",
        link: "https://www.researchgate.net/profile/Katharina-Zinn",
        id: 3,
      },
    ],
  },
  {
    image: "Paul_Hutchings.jpg",
    welshImage: "Paul_Hutchings_welsh.jpg",
    socialMediaLinks: [
      {
        type: "facebook",
        link: "https://www.facebook.com/paul.b.hutchings",
        id: 0,
      },
      {
        type: "twitter",
        link: "https://twitter.com/paulbhutchings?lang=en",
        id: 1,
      },
      {
        type: "other",
        text: "researchgate",
        link: "https://www.researchgate.net/profile/Paul-Hutchings",
        id: 2,
      },
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/paul-hutchings-9b7a508a/",
        id: 3,
      },
    ],
  },
  {
    image: "Sean_Jenkins.jpg",
    welshImage: "Sean_Jenkins_welsh.jpg",
    socialMediaLinks: [
      {
        type: "other",
        text: "uwtsd",
        link: "https://www.uwtsd.ac.uk/atic/",
        id: 0,
      },
      {
        type: "instagram",
        link: "https://www.instagram.com/aticuwtsd/?hl=en",
        id: 1,
      },
      {
        type: "facebook",
        link: "https://www.facebook.com/ATiCUWTSD/",
        id: 2,
      },
      {
        type: "twitter",
        link: "https://twitter.com/drseanjenkins1",
        id: 3,
      },
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/dr-sean-jenkins/",
        id: 4,
      },
    ],
  },
  {
    image: "Caroline_LohmannHancock.jpg",
    welshImage: "Caroline_LohmannHancock_welsh.jpg",
    socialMediaLinks: [
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/associate-professor-caroline-lohmann-hancock-9410a670/",
        id: 0,
      },
      {
        type: "twitter",
        link: "https://twitter.com/UWTSD_SocStud",
        id: 1,
      },
      {
        type: "other",
        text: "researchgate",
        link: "https://www.researchgate.net/profile/Caroline-Lohmann-Hancock ",
        id: 2,
      },
    ],
  },
  {
    image: "Julia_Lockheart.jpg",
    welshImage: "Julia_Lockheart_welsh.jpg",
    socialMediaLinks: [
      {
        type: "instagram",
        link: "https://www.instagram.com/dreamsid_artworks/",
        id: 0,
      },
      {
        type: "twitter",
        link: "https://twitter.com/drlockheart?lang=en",
        id: 1,
      },
      {
        type: "other",
        text: "julialockheart",
        link: "https://www.julialockheart.com/",
        id: 2,
      },
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/dr-julia-lockheart-30009712/?originalSubdomain=uk",
        id: 2,
      },
    ],
  },
  {
    image: "Nalda_Wainwright.jpg",
    welshImage: "Nalda_Wainwright_welsh.jpg",
    socialMediaLinks: [
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/dr-nalda-wainwright-03b5108b/",
        id: 0,
      },
    ],
  },
  {
    image: "Simon_Williams.jpg",
    welshImage: "Simon_Williams_welsh.jpg",
    socialMediaLinks: [
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/simon-ellis-williams-17732b18a/",
        id: 0,
      },
      {
        type: "twitter",
        link: "https://twitter.com/drsrpwilliams?lang=en",
        id: 1,
      },
    ],
  },
  {
    image: "Peter_Herbert.jpg",
    welshImage: "Peter_Herbert_welsh.jpg",
    socialMediaLinks: [
      {
        type: "linkedin",
        link: "https://www.linkedin.com/in/peter-herbert-1a3864171/?originalSubdomain=uk",
        id: 0,
      },
    ],
  },
  { image: "last_page.jpg", welshImage: "last_page_welsh.jpg" },
  { image: "back_cover.jpg", welshImage: "back_cover_welsh.jpg" },
];

function App() {
  const bookRef = useRef<any>();

  const [eng, setEng] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<any>(0);

  const pathname: string = window.location.pathname;

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false)
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  window.addEventListener('resize', handleWindowSizeChange);

  useEffect(() => {
    if(width < 768) {
      setIsMobile(true);
      alert("Please swipe from right to left, or left to right to navigate pages. Thank you.")
    } else{
      setIsMobile(false)
    }
  }, [width])

  return (
    <div className="App">
      <Button
        variant="contained"
        size="medium"
        className="button_lang"
        onClick={() => setEng(!eng)}
      >
        {eng ? "Cymraeg" : "English"}
      </Button>
      <div className="flipbook-nav-container">
        {!isMobile && (
            <>
              {currentPage !== 0 ? (
                <FaChevronCircleLeft
                  size="5vh"
                  className="next-page-button"
                  onClick={() => {
                    bookRef.current.pageFlip().flipPrev();
                  }}
                />
              ) : (
                <FaChevronCircleLeft
                  size="5vh"
                  className="next-page-button-disabled"
                  onClick={() => bookRef.current.pageFlip().flipPrev()}
                />
              )}
            </>
        )}

        {isMobile && (
          <img src={UWTSD} className={"mobileLogo"} alt={"UWTSD Logo"} />
         )}

        <div className="flipbook-container">
          {!isMobile ? (
            //Desktop version
            //@ts-ignore
            <HTMLFlipBook
            disableFlipByClick={false}
            startPage={pathname ? parseInt(pathname.split("/")[1]) + 1 : 0}
            width={700}
            height={990}
            size={isMobile ? "fixed" : "stretch"}
            usePortrait={true}
            maxShadowOpacity={0.2}
            showCover={true}
            mobileScrollSupport={true}
            ref={bookRef}
          >
            <PageCover image={"front_cover.svg"} lang={eng} />

            {book.map((b: any) => (
              <Page
                eng={eng}
                image={b.image}
                welshImage={b.welshImage}
                iframe={b.iframe}
                videoUrl={b.videoUrl}
                welshVideoUrl={b.welshVideoUrl}
                webLink={b.webLink}
                socialMediaLinks={b.socialMediaLinks}
                isMobile={isMobile}
              />
            ))}
          </HTMLFlipBook>
          ) : (
            //Mobile version
            //@ts-ignore
            <HTMLFlipBook
            disableFlipByClick={false}
            startPage={pathname ? parseInt(pathname.split("/")[1]) + 1 : 0}
            width={width}
            height={width * 1.4142}
            size={isMobile ? "fixed" : "stretch"}
            usePortrait={true}
            maxShadowOpacity={0.2}
            showCover={true}
            mobileScrollSupport={true}
            ref={bookRef}
          >
            <PageCover image={"front_cover.svg"} lang={eng} />

            {book.map((b: any) => (
              <Page
                eng={eng}
                image={b.image}
                welshImage={b.welshImage}
                iframe={b.iframe}
                videoUrl={b.videoUrl}
                welshVideoUrl={b.welshVideoUrl}
                webLink={b.webLink}
                socialMediaLinks={b.socialMediaLinks}
                isMobile={isMobile}
              />
            ))}
          </HTMLFlipBook>
          )}
          {/* @ts-ignore */}
          
        </div>
        {!isMobile && (
          <>
          {currentPage !== book.length - 1 ? (
            <FaChevronCircleRight
              size="5vh"
              className="next-page-button"
              onClick={() => {
                bookRef.current.pageFlip().flipNext();
              }}
            />
          ) : (
            <FaChevronCircleRight
              size="5vh"
              className="next-page-button-disabled"
              onClick={() => {
                bookRef.current.pageFlip().flipNext();
              }}
            />
          )}
        </>
        )}
      </div>
    </div>
  );
}

export default App;
