import { forwardRef } from "react";
import "./PageCover.css";

const PageCover = forwardRef((props: any, bookRef: any) => {
  return (
    <div className="page" ref={bookRef}>
      <div className="page-content front-cover">
        <img
          src={`${process.env.PUBLIC_URL}/images/UWTSD_200YearLogo.svg`}
          className={"logo"}
        />
        {props.lang ? (
          <div className="title">
            Professoriate
            <br />
            Directory
          </div>
        ) : (
          <div className="title">
            Cyfeiriadur
            <br />
            Athrawon
          </div>
        )}

        <div className="crest-wrapper">
          <img
            src={`${process.env.PUBLIC_URL}/images/crest.svg`}
            className="crest"
          />
        </div>

        <div className="triangle-wrapper">
          <div className="triangle" />
        </div>
      </div>
    </div>
  );
});

export default PageCover;
