import { forwardRef, useState } from "react";
import Iframe from "react-iframe";
import { IconButton, Button, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import "./Page.css";

interface PageProps {
  eng: boolean;
  image: string;
  welshImage: string;
  iframe: string;
  videoUrl: string;
  welshVideoUrl: string;
  webLink: string;
  socialMediaLinks: any[] | undefined;
  isMobile: boolean;
}

function openInNewTab(url: string) {
  if (window !== null && window.open !== null && url !== null) {
    //@ts-ignore
    window.open(url, "_blank").focus();
  }
}

const Page = forwardRef(
  (
    { eng, image, welshImage, iframe, videoUrl, welshVideoUrl, webLink, socialMediaLinks, isMobile }: PageProps,
    bookRef: any
  ) => {
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

    return (
      <div className={isMobile ? "mobilePage" : "page"} ref={bookRef}>
        <div className="page-container">
          <img
            src={
              eng
                ? `${process.env.PUBLIC_URL}/images/${image}`
                : `${process.env.PUBLIC_URL}/images/${welshImage}`
            }
            className={"pageImage"}
            alt="page"
          />
          {iframe ? (
            <div className="iframeWrapper">
              <Iframe url={iframe} className="iframe" allowFullScreen />
            </div>
          ) : null}

          {videoUrl ? (
            <div className="iframeWrapper_video">
              <Iframe url={eng ? videoUrl : welshVideoUrl} className="iframe" />
            </div>
          ) : null}

          <div className="socialWrapperOuter">
            <div className="socialWrapperInner">
              {/* Social media links  */}
              {socialMediaLinks !== undefined
                ? socialMediaLinks.map((val: any) => (
                    <>
                      {val.type === "linkedin" && (
                        <IconButton
                          className={"icon" + val.id}
                          key={val.id}
                          onClick={() => {
                            openInNewTab(val.link);
                          }}
                        >
                          <LinkedInIcon className={"socialIcon"} />
                          <Typography variant={"body1"}>LinkedIn</Typography>
                        </IconButton>
                      )}
                      {val.type === "facebook" && (
                        <IconButton
                          className={"icon" + val.id}
                          key={val.id}
                          onClick={() => {
                            openInNewTab(val.link);
                          }}
                        >
                          <FacebookIcon className={"socialIcon"} />
                          <Typography variant={"body1"}>Facebook</Typography>
                        </IconButton>
                      )}
                      {val.type === "twitter" && (
                        <IconButton
                          className={"icon" + val.id}
                          key={val.id}
                          onClick={() => {
                            openInNewTab(val.link);
                          }}
                        >
                          <TwitterIcon className={"socialIcon"} />
                          <Typography variant={"body1"}>Twitter</Typography>
                        </IconButton>
                      )}
                      {val.type === "instagram" && (
                        <IconButton
                          className={"icon" + val.id}
                          key={val.id}
                          onClick={() => {
                            openInNewTab(val.link);
                          }}
                        >
                          <InstagramIcon className={"socialIcon"} />
                          <Typography variant={"body1"}>Instagram</Typography>
                        </IconButton>
                      )}
                      {val.type === "other" && (
                        <IconButton
                          className={"socialLink"}
                          key={val.id}
                          onClick={() => {
                            openInNewTab(val.link);
                          }}
                        >
                          <LanguageIcon className={"socialIcon"} />
                          <Typography variant={"body1"}>{val.text}</Typography>
                        </IconButton>
                      )}
                    </>
                  ))
                : null}
            </div>
          </div>
          {webLink ? (
            <div className="buttonWrapper">
              <Button
                variant="contained"
                className="button"
                target="_blank"
                href={webLink}
                disableElevation
                size="small"
              >
                Visit Site
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

export default Page;
